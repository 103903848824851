.mainCont {
  background-color: rgb(221, 219, 219);
  padding-bottom: 20px;
  max-width: 430px;
  margin: auto;
}
.backCont {
  /* background-color: var(--mui-palette-primary-main); */
  height: 220px;
  border-radius: 0px 0px 50px 50px;
  background: linear-gradient(
    90deg,
    rgba(76, 76, 173, 1) 0%,
    rgba(100, 100, 246, 1) 100%
  );
}
.navCont {
  flex-direction: row !important;
  justify-content: space-between;
  padding: 5px 20px !important;
  align-items: center !important;
}
.leftIcon {
  color: #fff;
}
.logo {
  color: #fff;
  font-size: 20px !important;
  font-weight: 700 !important;
  font-style: italic !important;
}
.profileIcon {
  color: #fff;
  cursor: pointer;
}
.walletSection {
  background-color: #fff;
  margin: 10px 20px !important;
  border-radius: 20px;
  padding: 15px !important;
}
.amount {
  /* text-align: center !important; */
  font-size: 32px !important;
  font-weight: 700 !important;
  position: relative !important;
  margin: 8px 0px 0px 24px;
  height: 80px;
}
.reloadICon {
  position: absolute;
  /* left: 20px; */
  margin-left: 20px;
  margin-top: 2px;
}
.walletBalanceCont {
  flex-direction: row !important;
  align-items: center;
  /* justify-content: center !important; */
}
.walletIcon {
  width: 24px;
}
.balanceTxt {
  font-weight: 500 !important;
  margin-left: 7px !important;
  margin-top: 5px !important;
}
.btnCont {
  flex-direction: row !important;
  justify-content: end;
  margin-top: 15px !important;
  column-gap: 14px;
}
.stars {
  font-size: 21px;
  color: var(--mui-palette-primary-main);
}
.balance {
  color: #feaa57;
  margin-right: 10px;
  font-size: 18px;
}
.btnLogin {
  width: 100%;
  height: 40px;
  border-radius: 40px !important;
  background: linear-gradient(90deg, #f95959 0%, #ff9a8e 100%);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.1em !important;
  color: #fff !important;
  font-weight: 600 !important;
  transition: all 0.7s ease-in-out;
  text-transform: capitalize !important;
  margin: 8px 0px !important;
}
.contentCont {
  margin: 10px 20px;
  box-shadow: 0px 4px 6px 0px #464c8840;
  padding: 8px;
  border-radius: 12px;
  background-color: #fff;
}
.list {
  font-size: 13px !important;
  margin-bottom: 8px !important;
}
.listPointer {
  transform: rotate(45deg);
  width: 10px !important;
  height: 10px !important;
  color: var(--mui-palette-primary-main);
  margin-right: 3px !important;
}
.warnTxt {
  color: var(--mui-palette-primary-main) !important;
  font-size: 13px !important;
}
