.container {
    position: relative;
    padding: 0 !important;
    border-radius: 12px 12px 0px 0px;
    height: 40px;
    margin-left: 12px !important;
  }
  .tableHead {
    color: #0e194f;
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .greenTab {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 7px;
    background-color: var(--mui-palette-primary-main);
    border-radius: 20px;
    height: 28px;
    margin-top: 12px;
  }
  .headerContainer {
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    padding-left: 14px;
    border-radius: 12px 12px 0px 0px;
    text-transform: capitalize;
  }
  .tempCont{
    margin-top: 10px;
    position: relative;
  }
  .winnerImage{
    position:absolute;
    width: 180px;
    bottom: 30px;
    left: 120px;
  }
  .cardCont{
    flex-direction: row !important;
    height: 50px;
    background-color: #e5e3e3;
    margin: 12px;
    padding: 8px;
    padding-left: 18px;
    border-radius: 20px;
    align-items: center;
    box-shadow: 0px 4px 6px 0px #464C8840;
  }
  .gameName{
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    width: 150px;
  }
  .price{
    font-size: 16px;
    font-weight: 600;
  }
  .amountTxt{
    color: #768096;
    font-weight: 600;
  }