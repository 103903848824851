.mainCont {
  background-color: rgb(221, 219, 219);
  padding-bottom: 20px;
  max-width: 430px;
  margin: auto;
}
.backCont {
  /* background-color: var(--mui-palette-primary-main); */
  height: 260px;
  border-radius: 0px 0px 50px 50px;
  background: linear-gradient(
    90deg,
    rgba(76, 76, 173, 1) 0%,
    rgba(100, 100, 246, 1) 100%
  );
}
.navCont {
  flex-direction: row !important;
  justify-content: space-between;
  padding: 5px 20px !important;
  align-items: center !important;
}
.leftIcon {
  color: #fff;
}
.logo {
  color: #fff;
  font-size: 20px !important;
  font-weight: 700 !important;
  font-style: italic !important;
}
.profileIcon {
  color: #fff;
}
.walletSection {
  background-color: #fff;
  margin: 10px 20px !important;
  border-radius: 20px;
  padding: 15px !important;
}
.amount {
  text-align: center !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  position: relative !important;
}
.reloadICon {
  position: absolute;
  /* left: 20px; */
  margin-left: 20px;
  margin-top: 2px;
}
.walletBalanceCont {
  flex-direction: row !important;
  align-items: center;
  justify-content: center !important;
}
.walletIcon {
  width: 24px;
}
.balanceTxt {
  font-weight: 500 !important;
  margin-left: 7px !important;
  margin-top: 5px !important;
}
.btnCont {
  flex-direction: row !important;
  justify-content: space-between;
  margin-top: 15px !important;
}
.withdrawBtn {
  background-color: rgb(248, 79, 87) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 20px !important;
  padding: 5px 40px !important;
  font-weight: 700 !important;
}
.depositBtn {
  background-color: rgb(90, 196, 86) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 20px !important;
  padding: 5px 40px !important;
  font-weight: 700 !important;
}
.tabs {
  background-color: #fff;
  width: fit-content;
  margin: auto;
  border-radius: 10px;
  margin-top: 20px !important;
}
.tab {
  width: 70px !important;
  padding: 4px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.activeTab{
  background-color: var(--mui-palette-primary-main)  !important;
  color: #fff !important;
  opacity: 0.9;
}
.countDownCont {
  background-color: var(--mui-palette-primary-main);
  margin: 10px !important;
  border-radius: 20px !important;
  padding: 20px !important;
  flex-direction: row !important;
  justify-content: space-between;
  margin-top: 40px !important;
}
.howToPlayCont{
    flex-direction: row !important;
    align-items: center !important;
    border-radius: 20px !important;
    border: 1px solid #fff;
    padding: 3px 30px !important;
}
.htpTxt{
    color: #fff;
    font-size: 13px !important;
    font-weight: 600 !important;
}
.listIcon{
    color: #fff;
    width: 20px;
}
.winGoTxt{
    color: #fff;
    margin: 8px;
}
.divider{
    border-color: #fff !important;
    border-style: dashed !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.rightCont{
    align-items: end;
    row-gap: 5px !important;
}
.timeTxt{
    color: #fff;
    text-align: left !important;
    font-weight: 700 !important;
    font-size: 15px !important;
}
.timerCont{
    flex-direction: row !important;
}
.item{
    font-size: 20px !important;
    font-weight: 700 !important;
    background-color: #fff;
    margin-left: 5px;
    padding: 5px;
}
.id{
    font-size: 18px !important;
    color: #fff;
    font-weight: 600 !important;
}
.tempCont{
    background-color: #fff;
    margin: 10px !important;
    border-radius: 10px !important;
    padding: 10px;
    flex-direction: row !important;
    justify-content: space-between;
}
.grnClr{
    border-radius: 0px 10px 0px 10px;
    padding: 4px 30px !important;
    background-color: #18b660;
    color: #fff;
    font-weight: 600 !important;
}
.vltClr{
    border-radius: 10px;
    padding: 4px 30px !important;
    background-color: #c86eff;
    color: #fff;
    font-weight: 600 !important;
}
.redClr{
    border-radius: 10px 0px 10px 0px;
    padding: 4px 30px !important;
    background-color: #fb5b5b;
    color: #fff;
    font-weight: 600 !important;
}