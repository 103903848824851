.container{
    background-color: rgb(224, 247, 249);
    padding: 10px !important;
}
.itemCont{
    flex-direction: row !important;
    align-items: center !important;
    margin: 10px;
    padding-right: 20px !important;
}
.label{
    width: 100%;
    font-size: 14px !important;
}