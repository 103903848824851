.container {
  /* box-shadow: 0 2px 8.6px -2px rgba(0, 0, 0, 0.2);  */

  box-shadow: 0px 2px 8.6px 0px #00000017;
  position: relative;
  padding: 0 !important;
  border-radius: 12px 12px 0px 0px;
  height: 48px;
}

.tableHead {
  color: #67686b;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.greenTab {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  background-color: var(--mui-palette-primary-main);
  border-radius: 0px 12px 12px 0px;
  height: 32px;
  margin-top: 12px;
}

.headerContainer {
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  padding-top: 15px;
  padding-left: 18px;
  border-radius: 12px 12px 0px 0px;
  text-transform: capitalize;
}

.tabContainer {
  margin: 32px 44px 0px 44px;
}

.tabHead {
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: capitalize;
}
.btnClass{
}
@media (max-width: 480px) {
  .container {
    height: 40px;
  }
  .headerContainer {
    padding: 8px;
    padding-top: 10px;
    padding-left: 14px;
    border-radius: 12px 12px 0px 0px;
  }
  .tabHead {
    font-size: 8px !important;
  }
  .tableHead {
    font-size: 16px !important;
  }
  .greenTab {
    width: 4px;
    height: 25px;
    margin-top: 10px;
  }
  .btn{
    font-size: 9px !important;
    padding: 3px 12px;
  }
}
