.headerContainer {
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    padding-top: 0px;
    padding-left: 0px;
    border-radius: 12px 12px 0px 0px;
    text-transform: capitalize;
    margin-bottom: 16px !important;
  }
  .tableHead {
    color: #262525de !important;
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .btnClass{
    font-weight: 600 !important;
  }

.dataGridComp{
    margin-bottom: 20px !important;
    box-shadow: 0px 2px 8.6px 0px #00000017;
    border-radius: 20px;
    /* padding: 10px !important; */
  }
  .datagridHead{
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #262525de !important;
    margin-bottom: 12px !important;
  }