.nav {
    background-color: #fff !important;
  }
  .logo {
    flex-grow: 1;
    font-weight: 700 !important;
    font-style: italic;
  }
  .loginBtn {
    text-transform: capitalize !important;
    color: #4c4cad !important;
    border-color: #4c4cad !important;
    padding: 1px 20px !important;
  }
  .registerBtn {
    text-transform: capitalize !important;
    color: #fff !important;
    background: linear-gradient(135deg, var(--mui-palette-primary-main) 0%, hsla(218, 90%, 70%, 1) 100%) !important;
    padding: 1px 20px !important;
    margin-left: 12px !important;
  }
  .profileCont{
    flex-direction: row !important;
    align-items: center;
  }
  .avatar{
    background-color: var(--mui-palette-primary-main) !important;
    font-weight: 600 !important;
    width: 29px;
    height: 29px;
    margin-right: 5px;
  }
  .name{
    font-size: 12px !important;
    color: grey;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .menuitem{
    width: 150px;
    color: #656464;
  }
  .menuIco{
    margin-right: 10px !important;
    color: #656464;
  }
  