.container{
    
}

.footer{
    justify-content: end;
    margin: 20px 0px 0px 0px
}

.dataNotFound{
    justify-content: center;
    align-items: center;
    color: grey;
    /* border-right: 1px solid #CED4DA; */
    /* width: 100%; */
    height: 100%;
}