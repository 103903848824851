.container {
  position: relative;
  padding: 0 !important;
  border-radius: 12px 12px 0px 0px;
  height: 40px;
  margin-left: 12px !important;
}
.tableHead {
  color: #0e194f;
  font-size: 20px !important;
  font-weight: 500 !important;
}
.greenTab {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 7px;
  background-color: #4c4cad;
  border-radius: 20px;
  height: 28px;
  margin-top: 12px;
}
.headerContainer {
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  padding-left: 14px;
  border-radius: 12px 12px 0px 0px;
  text-transform: capitalize;
}
.cardSection{
    margin: 12px !important;
    border-radius: 12px !important;
    display: flex;
    cursor: pointer;
}
.cardContent{
    background: linear-gradient(135deg, hsla(240, 75%, 47%, 1) 0%, hsla(218, 40%, 48%, 1) 100%) !important;
    flex: 1;
    color: #fff;
    font-weight: 500 !important;
}
.cardHead{
    font-size: 22px !important;
    font-weight: 700 !important;
    background: linear-gradient(135deg, hsla(51, 99%, 46%, 1) 0%, hsla(311, 81%, 59%, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.cardText{
    font-size: 14px !important;
    font-weight: 500 !important;
    background: linear-gradient(135deg, hsla(51, 99%, 46%, 1) 0%, hsla(311, 81%, 59%, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}