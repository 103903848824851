.countDownCont {
  background-color: var(--mui-palette-primary-main);
  margin: 10px !important;
  border-radius: 20px !important;
  padding: 14px !important;
  flex-direction: row !important;
  justify-content: space-between;
  margin-top: 40px !important;
}
.howToPlayCont {
  flex-direction: row !important;
  align-items: center !important;
  border-radius: 20px !important;
  border: 1px solid #fff;
  padding: 3px 20px !important;
  width: 100px !important;
}
.inActiveTab {
  justify-content: center;
  border-radius: 20px !important;
  background-color: #fff;
  margin: auto !important;
  display: flex;
  padding: 5px 18px !important;
  width: fit-content;
  color: var(--mui-palette-primary-main);
  font-size: 16px !important;
  font-weight: 700 !important;
}
.histHead {
  justify-content: center;
  border-radius: 20px !important;
  background-color: var(--mui-palette-primary-main);
  margin: auto !important;
  display: flex;
  padding: 5px 18px !important;
  width: fit-content;
  color: #fff;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.htpTxt {
  color: #fff;
  font-size: 13px !important;
  font-weight: 600 !important;
  cursor: pointer;
}
.listIcon {
  color: #fff;
  width: 20px;
}
.winGoTxt {
  color: #fff;
  margin: 5px !important;
}
.greenColorItem {
  font-weight: 700 !important;
  color: #3cb23c !important;
  width: fit-content !important;
  padding: 0px 7px !important;
  border-radius: 25px !important;
  border: 6px dashed transparent;
  border-image: linear-gradient(
    122deg,
    rgba(200, 110, 255, 1) 49%,
    rgba(24, 182, 96, 1) 49%
  );
  background: linear-gradient(
    122deg,
    rgba(200, 110, 255, 1) 49%,
    rgba(24, 182, 96, 1) 49%
  );
  background-repeat: no-repeat;
}
.redColorItem {
  font-weight: 700 !important;
  color: #df422e !important;
  border-image: linear-gradient(
    122deg,
    rgba(200, 110, 255, 1) 49%,
    rgba(24, 182, 96, 1) 49%
  );
  width: fit-content !important;
  padding: 0px 7px !important;
  border-radius: 25px !important;
  border: 6px dashed;
  border-image: linear-gradient(
    122deg,
    rgba(200, 110, 255, 1) 49%,
    rgba(24, 182, 96, 1) 49%
  );
}
.violetColorItem {
  font-weight: 700 !important;
  color: #c86eff !important;
  background-color: #eddef6;
  width: fit-content !important;
  padding: 0px 7px !important;
  border-radius: 25px !important;
  border: 6px dashed;
}
.divider {
  border-color: #fff !important;
  border-style: dashed !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.rightCont {
  align-items: end;
  row-gap: 5px !important;
}
.timeTxt {
  color: #fff;
  text-align: left !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.timerCont {
  flex-direction: row !important;
}
.item {
  font-size: 18px !important;
  font-weight: 700 !important;
  background-color: #fff;
  margin-left: 5px;
  padding: 5px;
}
.id {
  font-size: 16px !important;
  color: #fff;
  font-weight: 600 !important;
}
.tempCont {
  background-color: #fff;
  margin: 10px !important;
  border-radius: 10px !important;
  padding: 10px;
  flex-direction: row !important;
  justify-content: space-between;
}
.grnClr {
  border-radius: 0px 10px 0px 10px;
  padding: 4px 30px !important;
  background: #18b660;
  color: #fff;
  font-weight: 600 !important;
}
.vltClr {
  border-radius: 10px;
  padding: 4px 30px !important;
  background-color: #c86eff;
  color: #fff;
  font-weight: 600 !important;
}
.redClr {
  border-radius: 10px 0px 10px 0px;
  padding: 4px 30px !important;
  background-color: #fb5b5b;
  color: #fff;
  font-weight: 600 !important;
}
.number {
  width: 45px;
}
.numberShort {
  width: 28px;
}
.bigSmallCont {
  background-color: #fff;
  margin: 10px !important;
  border-radius: 10px !important;
  padding: 10px 50px;
  flex-direction: row !important;
  justify-content: space-between;
}
.withdrawBtn {
  background-color: rgb(248, 79, 87) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 20px 0px 0px 20px !important;
  padding: 5px 40px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  width: 50%;
}
.depositBtn {
  background-color: rgb(90, 196, 86) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 0px 20px 20px 0px !important;
  padding: 5px 40px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  width: 50%;
}
