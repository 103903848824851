.header {
    justify-content: space-between;
    background-color: var(--mui-palette-primary-contrastText);
    /* width: 100% !important; */
    padding: 12px 16px 12px 16px;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
  }
  
  .header {
    justify-content: center;
    background-color: var(--mui-palette-primary-main);
    padding: 12px 16px 12px 16px;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
    text-transform: capitalize;
  }
  .noteHead {
    margin: 20px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #1b3e61;
    word-break: break-all;
  }
  .heading {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .icon {
    width: 25px;
    cursor: pointer;
  }
  .noteDescription {
    margin: 20px !important;
    color: #6c757d;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-wrap: wrap;
    word-wrap: break-word;
    justify-content: center;
  }
  .loadingCont {
    height: 100px;
    justify-content: center;
    align-items: center;
  }
  .loaderCont {
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
  }
  .reason {
    padding: 20px !important;
  }
  .contractMoney{
    font-size: 16px !important;
    color: grey;
    text-align: center;
    margin-bottom: 8px;
  }
  .btnCont {
    align-items: end !important;
    padding-bottom: 16px;
    padding-right: 20px !important;
    flex-direction: row !important;
    justify-content: end !important;
  }
  .btnCloseClass {
    width: fit-content;
    background-color: grey !important;
    height: 25px !important;
    font-weight: 700;
  }
  .btnConfirmClass{
    background-color: var(--mui-palette-primary-main);
    height: 25px !important;
    margin-left: 8px !important;
  }
  .btnAcceptClass{
    background-color: green;
    height: 25px !important;
    margin-left: 8px !important;
    font-weight: 700;
  }
  .btnRejectClass{
    background-color: red;
    height: 25px !important;
    margin-left: 8px !important;
    font-weight: 700;
  }
  