.heading {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #262525de !important;
}
.cardCont {
  flex-direction: row !important;
  row-gap: 30px !important;
  column-gap: 40px !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  flex-wrap: wrap;
  justify-content: space-between;
}
.item {
  height: 70px;
  width: 250px;
  background: linear-gradient(
    140deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
  border-radius: 10px;
  /* align-items: center !important; */
  /* justify-content: center; */
  align-items: center;
  padding: 10px !important;
  flex-direction: row !important;
}
.icon {
  width: 38px;
  padding: 6px;
  height: 38px;
  border-radius: 3px;
  background-color: rgba(212, 210, 210, 0.4);
  margin-left: 5px !important;
}
.itemHead {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #e8e5e5;
}
.itemCount {
  font-size: 18px !important;
  color: #e8e5e5;
  font-weight: 600 !important;
}
.dataGridComp {
  margin-bottom: 20px !important;
  box-shadow: 0px 2px 8.6px 0px #00000017;
  border-radius: 20px;
  /* padding: 10px !important; */
}
.datagridHead {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #262525de !important;
  margin-bottom: 12px !important;
}
.tableHead {
  color: #262525de !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}
.btnClass {
  font-weight: 600 !important;
}
.headerContainer {
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  padding-top: 0px;
  padding-left: 0px;
  border-radius: 12px 12px 0px 0px;
  text-transform: capitalize;
  margin-bottom: 16px !important;
}
