.container {
    position: relative;
    padding: 0 !important;
    border-radius: 12px 12px 0px 0px;
    height: 40px;
    margin-left: 12px !important;
  }
  .tableHead {
    color: #0e194f;
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .greenTab {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 7px;
    background-color: #4c4cad;
    border-radius: 20px;
    height: 28px;
    margin-top: 12px;
  }
  .headerContainer {
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    padding-left: 14px;
    border-radius: 12px 12px 0px 0px;
    text-transform: capitalize;
  }
  .contentCont{
    margin: 10px;
    box-shadow: 0px 4px 6px 0px #464C8840;
    padding: 8px;
    border-radius: 12px;
  }
  .list{
    font-size: 13px !important;
    margin-bottom: 8px !important;
  }
  .listPointer{
    transform: rotate(45deg);
    width: 10px !important;
    height: 10px !important;
    color: var(--mui-palette-primary-main);
    margin-right: 3px !important;
  }
  .warnTxt{
    color: var(--mui-palette-primary-main) !important;
    font-size: 13px !important;
  }