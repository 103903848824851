.heading {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #262525de !important;
    margin-bottom: 16px !important;
  }

.dataGridComp{
    margin-bottom: 20px !important;
    box-shadow: 0px 2px 8.6px 0px #00000017;
    border-radius: 20px;
    /* padding: 10px !important; */
  }
  .datagridHead{
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #262525de !important;
    margin-bottom: 12px !important;
  }