.header {
    justify-content: space-between;
    background-color: #1b3e61;
    /* width: 100% !important; */
    padding: 12px 16px 12px 16px;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
  }
  .heading {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .icon {
    width: 25px;
    cursor: pointer;
  }
  .formCont {
    padding: 30px 16px 16px 16px;
    row-gap: 16px;
  }
  .label {
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-bottom: 8px !important;
  }
  
  .select {
    width: 355px !important;
    height: 40px !important;
    background-color: #fff;
  }
  .inputFeild {
    flex-direction: row;
    column-gap: 20px;
  }
  .btn{
      width: 100px
  }
  
  @media (max-width: 600px) {
    .heading {
      font-size: 14px !important;
      font-weight: 700 !important;
    }
    .icon {
      width: 25px;
      cursor: pointer;
    }
    .header {
      padding: 10px 12px 4px 12px;
      border-radius: 6px 6px 0px 0px;
    }
    .formCont {
      padding: 16px;
      row-gap: 8px;
    }
    .label {
      font-size: 12px !important;
      margin-bottom: 4px !important;
    }
    .selectComp{
      width: 150px !important;
    }
    .inputFeild {
      column-gap: 10px;
    }
    .inputFeild li{
      font-size: 10px !important;
      padding: 8px !important;
      min-height: 20px !important;
    }
    .menuItem{
      font-size: 12px !important;
      padding: 8px !important;
      min-height: 20px !important;
    }
    .textarea > div{
      font-size: 12px !important;
    }
  .btn{
    width: 80px;
    font-size: 12px !important;
    padding: 4px !important;
  }
  
  }
  