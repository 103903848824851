.mainContainer {
  height: 100vh;
  width: 100vw;
  background-image: url("../../../utils/images/bg_banner.png");
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}
.form {
  width: 311px;
  box-sizing: border-box;
  background: transparent;
  border: none;
  border-radius: 20px;
  backdrop-filter: blur(15px) brightness(80%);
  padding: 30px;
}
.loginTxt {
  font-size: 2em !important;
  font-weight: 700 !important;
  color: #fff;
  text-align: center;
}
.textfieldCont {
  margin: 20px 0px !important;
}
.doNotAcc {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin: 14px 0px !important;
}
.signupText {
  font-weight: 700 !important;
  cursor: pointer !important;
}
.rememberCont {
  justify-content: space-between;
  flex-direction: row !important;
  align-items: center;
  margin-top: -5px !important;
  margin-bottom: 8px !important;
}
.checkboxCont {
  flex-direction: row !important;
  align-items: center;
}
.checkbox {
  color: #fff;
  width: 4px;
}
.rememberTxt {
  color: #fff;
}
.forgotTxt {
  color: #fff;
}
.btnLogin {
  width: 100%;
  height: 40px;
  border-radius: 40px !important;
  background-color: #fff !important;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.1em !important;
  color: rgb(13, 13, 98) !important;
  font-weight: 600 !important;
  transition: all 0.7s ease-in-out;
  text-transform: capitalize !important;
  margin: 8px 0px !important;
}
.gotoHome {
  text-align: center;
  font-size: 24px !important;
  font-weight: 900 !important;
  color: #fff;
  margin: 12px 0px !important;
  cursor: pointer;
}
.captchaCont {
  padding: 0px 20px;
  background-color: #fff;
}
.captchaBtn {
  width: 50%;
  height: 30px;
  border-radius: 30px !important;
  background-color: #fff !important;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 12px !important;
  color: rgb(13, 13, 98) !important;
  font-weight: 600 !important;
  transition: all 0.7s ease-in-out;
  text-transform: capitalize !important;
  margin: 8px 0px 8px 8px !important;
}
.verifyOtpBtn {
  /* width: 50%; */
  height: 30px;
  border-radius: 30px !important;
  background-color: #fff !important;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 12px !important;
  color: rgb(13, 13, 98) !important;
  font-weight: 600 !important;
  transition: all 0.7s ease-in-out;
  text-transform: capitalize !important;
  margin: 8px 0px 0px 0px !important;
}
