.cont {
  margin-top: 70px !important;
}
.image {
  height: 200px !important;
  width: 95%;
  border-radius: 20px !important;
  margin: 12px !important;
  box-shadow: 0px 02px 8px;
}
.imageCont {
}
