.appBar{
    background-color: var(--mui-palette-primary-main);
    font-weight: 700 !important;
}
.navHead{
    font-weight: 700 !important;
    font-style: italic;
    color: #fff;
}
.mainHeader{
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between !important;
    width: 100% !important;
}
.adminTxt{
    font-style: normal;
    font-size: 14px !important;
    font-weight: 700 !important;
    cursor: pointer;
}
.icon{
    width: 24px !important;
}
.iconWithdraw{
    width: 24px !important;
    opacity: 0.6;
}
.drawer{
    background-color: var(--mui-palette-primary-main);
    height: 65px !important;
    margin-right: -2px !important;
    align-items: center;
    justify-content: center;
}