.container {
  background-color: rgb(224, 247, 249);
  padding: 10px !important;
}
.itemCont {
  flex-direction: row !important;
  align-items: center !important;
  margin: 10px;
  padding-right: 20px !important;
}
.label {
  width: 100%;
  font-size: 14px !important;
}
.dataGridComp {
  margin-bottom: 20px !important;
  box-shadow: 0px 2px 8.6px 0px #00000017;
  border-radius: 20px;
  /* padding: 10px !important; */
}
.withdrawBtn {
  background-color: rgb(248, 79, 87) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 20px !important;
  padding: 2px 10px !important;
  font-weight: 700 !important;
  font-size: 13px !important;
}
.depositBtn {
  background-color: rgb(90, 196, 86) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 20px !important;
  padding: 2px 10px !important;
  font-weight: 700 !important;
  font-size: 13px !important;
}
